export default defineAppConfig({
  contact: [
    {
      type: 'Mail',
      content: 'mail@lora.ch'
    },
    {
      type: 'Phone',
      content: '0041445672400'
    },
    {
      type: 'Facebook',
      content: 'https://www.facebook.com/LoRa97.5'
    },
    {
      type: 'Instagram',
      content: 'https://www.instagram.com/radiolora97.5'
    }

  ],
  mainNav: [
    {
      label: 'Radio',
      children: [
        {
          label: 'Programm', link: '/radio/programm'
        },
        {
          label: 'Sendungen', link: '/radio/sendungen'
        },
        {
          label: 'Audiothek', link: '/radio/audiothek'
        },
        {
          label: 'Tracklist', link: '/radio/tracklist'
        }
      ],
      link: null
    },
    {
      label: 'Aktuell',
      children: null,
      link: '/aktuell'
    },
    {
      label: 'Mitmachen',
      children: [
        {
          label: 'Mitglied werden', link: '/mitmachen/mitglied-werden'
        },
        {
          label: 'Sendung machen', link: '/mitmachen/sendung-machen'
        },
        {
          label: 'Spenden', link: '/mitmachen/spenden'
        }
      ],
      link: '/mitmachen'
    },
    {
      label: 'Über Uns',
      children: [
        {
          label: 'Radio LoRa', link: '/ueber-uns/radio-lora'
        },
        {
          label: 'Redaktionen', link: '/ueber-uns/redaktionen'
        },
        {
          label: 'Kontakt', link: '/ueber-uns/kontakt'
        },
        {
          label: 'Partnerschaften', link: '/ueber-uns/partnerschaften'
        },
        {
          label: 'LoRa Info', link: '/ueber-uns/lora-info'
        },
        {
          label: 'Dokumente', link: '/ueber-uns/dokumente'
        }
      ],
      link: null
    }
  ],
  footerNav: [
    { label: 'Kontakt', link: '/ueber-uns/kontakt' },
    { label: 'Spenden', link: '/mitmachen/spenden' },
    { label: 'Rechtliches', link: '/rechtliches' }
  ],
  footerText: 'Lora empfangen (UKW, DAB): Hier kommt ein kleiner Text darüber wie Radio LoRa gehört werden kann.',
  claim: 'Das freie Radio <br> von Zürich',
  defaultPageSize: 10,
  defaultPageSizeList: 20,
  defaultPageSizeGrid: 24,
  filterDebounceDuration: 200,
  languages: [
    {
      code: 'de',
      name: 'Deutsch'
    },
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'fa',
      name: 'فارسی'
    },
    {
      code: 'ar',
      name: 'العربية'
    },
    {
      code: 'fr',
      name: 'Français'
    },
    {
      code: 'it',
      name: 'Italiano'
    },
    {
      code: 'es',
      name: 'Español'
    },
    {
      code: 'tr',
      name: 'Türkçe'
    }
  ],
  credentials: {
    mapbox: {
      // @todo: This needs to be changed.
      accessToken:
        'pk.eyJ1IjoiYXR0cmlidXRlIiwiYSI6ImNsdm1kZm9nNzAwcXEya28xODZnb3JxYXoifQ.7ogbteQ3XH00MFbmXtkSAw'
    }
  },
  colors: [
    '#207DFF',
    '#FF4600',
    '#84FFD4',
    '#009713',
    '#FFFF4E',
    '#7933FF',
    '#B5B795',
    '#888100',
    '#FFC0FF'
  ],
  noResults: 'Keine Resultate'
})
